<style scoped>
.svgContent {
  width: 100%;
  height: 700px;
  text-align: center;
}
</style>

<template>
    <Modal v-model="isShow" width="1200" :footer-hide="true" :fullscreen="isFullscreen">
        <Row :gutter="16" style="height: 700px;">
            <div class="fullscreen-content" @click="isFullscreen = !isFullscreen">
                <Icon v-if="isFullscreen" type="ios-contract" size="16" />
                <Icon v-else type="ios-expand" size="16" />
            </div>

            <i-col span="20">
                <div v-html="svgHtml" id="svgWorkPlatform" class="svgContent" style="background-color:#1A1A1A;"></div>
            </i-col>
            <i-col span="4">
                <div class="dispatch-left-title">品牌进度</div>
                <div>
                    <Button type="info" size="small" @click="handleChooseBrand(null)">显示全部上刊</Button>
                    <Checkbox v-model="autoPlay" class="m-l-10">自动播放</Checkbox>
                </div>

                <p v-for="(brand,index) in brandList" :key="brand.brandId" class="p-t-10" >
                    <Icon v-if="index<3" type="md-star" color="#ff9900" style="margin-left: -12px;"/>
                    <a :class="chooseBrandId===brand.brandId?'dispatch-bottom-link-active':'dispatch-bottom-link'" @click="handleChooseBrand(brand.brandId)">
                        {{brand.brandName}}({{brand.finishedQuantity}}/{{brand.finishedQuantity+brand.executingQuantity}})
                    </a>
                </p>
            </i-col>
        </Row>

        <Spin size="large" fix v-if="spinShow">
          <Icon type="ios-loading" size=18 class="-spin-icon-load"></Icon>
          <div>地图加载中</div>
        </Spin>
    </Modal>
</template>

<script>
import svgPanZoom from 'svg-pan-zoom'
import { getCitys } from '@/api/product/asset'
import { getCitySvg } from '@/api/rim/asset'
import { generateResourceImageMarker, generateResourceTextMarker, clearMapMarker, checkStationidInList, checkTextPoint } from '@/utils/mapCommon'
import { getBrandProgress, getFinishProgress } from '@/api/msp/taskwork'

export default {
  data () {
    return {
      isFullscreen: false,
      isShow: false,
      spinShow: false,
      svgHtml: '',

      publisherId: this.$store.getters.token.userInfo.publisherId,
      pathNodeList: [],
      gNodeList: [],
      assetColors: new Map(),
      currentMapObj: null,

      autoPlay: false,
      playing: false,
      brandList: [],
      chooseBrandId: null,
      chooseBrandHistory: null
    }
  },
  methods: {
    initMap () {
      this.spinShow = true
      getCitys({ publisherId: this.publisherId }).then(citys => {
        const data = { cityCode: citys[0].cityCode, publisherId: this.publisherId }

        getCitySvg(data).then(res => {
          this.svgHtml = res
          // 设置SVG地图的样式和缩放
          this.$nextTick(() => {
            if (!document.getElementById('svgWorkPlatform')) { return }
            // 设置svg呈现在画板上的尺寸
            document.getElementById('svgWorkPlatform').firstChild.setAttribute('width', '100%')
            document.getElementById('svgWorkPlatform').firstChild.setAttribute('height', '100%')

            this.currentMapObj = svgPanZoom('#svgWorkPlatform #drawing', {
              panEnabled: true,
              controlIconsEnabled: false,
              zoomEnabled: true,
              dblClickZoomEnabled: true,
              minZoom: 1,
              maxZoom: 5,
              center: true
            })

            if (this.publisherId === 12) { // 厦门地图不能放太大
              this.currentMapObj.zoom(1.5)
            } else {
              this.currentMapObj.zoom(3)
            }

            this.pathNodeList = document.getElementsByTagName('path')
            this.gNodeList = document.getElementsByTagName('g')

            if (this.pathNodeList.length > 0) {
              for (let i = 0; i < this.pathNodeList.length; i++) {
                const el = this.pathNodeList[i]
                if (el.hasAttribute('data-stationid')) {
                  if (el.nextSibling === null) {
                  // 移动SVG站台元素到容器最后方，属于SVG的z-index
                    el.parentElement.parentElement.appendChild(el.parentElement)
                  }

                  // 所有站点文字变为灰色
                  if (el.nextSibling.nextElementSibling) {
                    el.nextSibling.nextElementSibling.children[0].setAttribute('fill', 'lightgrey')
                  }
                }

                // 替换大海颜色
                if (el.hasAttribute('fill') && el.getAttribute('fill') === '#d5efff') {
                  el.setAttribute('fill', '#17233d')
                  el.setAttribute('stroke', '#17233d')
                }
              }
            }

            this.loadBrandAndFinishProgress()
            this.spinShow = false
          })
        })
      })
    },
    loadBrandAndFinishProgress () { // 载入品牌进度
      const postData = { actionDate: this.actionDate }
      getBrandProgress(postData).then(res => {
        this.brandList = res
      })

      this.handleChooseBrand(null)
    },
    handleChooseBrand (val) {
      this.chooseBrandId = val
      const postData = {
        actionDate: this.actionDate,
        brandId: val
      }

      getFinishProgress(postData).then(res => {
        const stations = res.filter(x => x.finishedQuantity > 0).map(res => {
          return {
            stationId: res.domainId,
            quantity: res.finishedQuantity,
            endTime: res.endTime
          }
        })
        if (this.autoPlay) {
          clearMapMarker()
          this.autoPlayTagetStation(stations, 0)
        } else {
          clearMapMarker()
          this.loadResourceStations(stations)
        }
      })
    },
    autoPlayTagetStation (stations, startIndex) {
      if (startIndex < stations.length && this.isShow) {
        // 自动循环播放添加站点
        this.handleMapGetCenter(stations[startIndex].stationId.toString())

        // 1秒后添加气泡
        setTimeout(() => {
          this.loadResourceStations([stations[startIndex]])
        }, 1000)

        // 2秒后继续加载其他站点
        setTimeout(() => {
          this.autoPlayTagetStation(stations, ++startIndex)
        }, 2000)
      }
    },
    loadResourceStations (stations) {
      // 遍历添加新的数据
      if (this.pathNodeList.length > 0) {
        stations.forEach(element => {
          for (let i = 0; i < this.pathNodeList.length; i++) {
            const el = this.pathNodeList[i]
            // 站点ID，不同的传递
            const pathStationId = element.id ? element.id.toString() : element.stationId.toString()
            if (el.hasAttribute('data-stationid') && el.getAttribute('name') !== 'standardText' && checkStationidInList(el.getAttribute('data-stationid'), pathStationId)) {
              // 将图标气泡添加到当前站点上方
              if (el.nextSibling === null || el.nextSibling.nodeName === '#text') {
                // 库存页面显示无余量点位, 直接标注绿色，用于允许点击气泡查看SVG
                if (element.nothingQuantity) {
                  el.after(generateResourceImageMarker(this, el.getAttribute('data-stationid'), el.getAttribute('name'), 'green'))
                } else {
                  el.after(generateResourceTextMarker(this, element.quantity, el.getAttribute('data-stationid'), el.getAttribute('name')))
                  // 显示所有红色气泡
                  el.after(generateResourceImageMarker(this, el.getAttribute('data-stationid'), el.getAttribute('name')), 'red')
                }
              } else if (el.nextSibling.nextSibling.innerHTML) {
                // 在原有的数值上添加
                const stationQuantity = parseInt(el.nextSibling.nextSibling.innerHTML) + element.quantity
                if (stationQuantity > 99) {
                  el.nextSibling.nextSibling.setAttribute('x', checkTextPoint(el.getAttribute('name'))[0] - 8)
                } else if (stationQuantity > 9) {
                  el.nextSibling.nextSibling.setAttribute('x', checkTextPoint(el.getAttribute('name'))[0])
                } else { el.nextSibling.nextSibling.setAttribute('x', checkTextPoint(el.getAttribute('name'))[0] + 5) }
                el.nextSibling.nextSibling.innerHTML = stationQuantity
              }
            }
          }
        })
      }
    },
    handleMapGetCenter (stationId) {
      let centerPoint
      let pEl

      for (let i = 0; i < this.pathNodeList.length; i++) {
        pEl = this.pathNodeList[i]
        if (pEl.hasAttribute('data-stationid') && (pEl.getAttribute('name') === 'p_station' || pEl.getAttribute('name') === 'p_r_station') &&
          checkStationidInList(pEl.getAttribute('data-stationid'), stationId)) {
          const centerPointStr = pEl.parentNode.getAttribute('transform').substr(10).split(')')[0].split(' ')
          centerPoint = {
            id: pEl.getAttribute('data-stationid'),
            x: parseInt(centerPointStr[0]),
            y: parseInt(centerPointStr[1])
          }
          // 退出循环
          break
        }
      }

      // 计算出实际跳转坐标
      const svgSizes = this.currentMapObj.getSizes()
      if (centerPoint) { // 处理关闭窗口后的空值
        const newPoint = {
          x: -(centerPoint.x * svgSizes.realZoom) + (svgSizes.width / 2),
          y: -(centerPoint.y * svgSizes.realZoom) + (svgSizes.height / 2)
        }

        this.handleMapAnimation(newPoint)
      }
    },
    handleMapAnimation (targetPoint) {
      const _this = this
      const currentPan = this.currentMapObj.getPan()

      var animationTime = 300 // ms
      var animationStepTime = 30 // one frame per 30 ms
      var animationSteps = animationTime / animationStepTime
      var animationStep = 0
      var intervalID = null
      var stepX = (currentPan.x - targetPoint.x) / animationSteps
      var stepY = (currentPan.y - targetPoint.y) / animationSteps

      intervalID = setInterval(function () {
        if (animationStep++ < animationSteps) {
          _this.currentMapObj.pan({ x: currentPan.x - stepX * animationStep, y: currentPan.y - stepY * animationStep })
        } else {
          // 取消无限循环
          clearInterval(intervalID)
        }
      }, animationStepTime)
    }
  },
  computed: {
    actionDate () {
      return this.$store.state.dispatch.actionDate
    },
    lineDemandHistory () {
      return this.$store.state.dispatch.lineDemandHistory
    }
  },
  watch: {
    lineDemandHistory (val) {
      if (val) {
        this.isShow = true
        this.initMap()
      }
    },
    isShow (val) {
      if (!val) {
        this.svgHtml = ''
        this.$store.commit('set_dispatch_show_lineDemandHistory', false)
      }
    }
  }
}
</script>
