<template>
    <div class="dispatch-right">
        <p class="text-center p-t-10 text-20">实时调度</p>
        <section v-for="msg in msgList" :key="msg.id" class="dispatch-right-message" @click="handleClickMessage(msg)">
            <h3 class="text-red">
              {{msg.typeName}}
              <span class="remark">{{msg.createTime.substr(11)}}</span>
            </h3>
            <p>线路：{{msg.assetName}}</p>
            <p>站点：{{msg.domainName}}</p>
            <p>内容：{{msg.content}}</p>
        </section>
        <p v-if="totalRow===0" class="text-center p-t-20 text-16 text-gray" >暂无数据</p>

        <div v-if="totalRow>0" class="paging_style">
            <Page :total="totalRow" size="small"  @on-change="handlePageChange"/>
        </div>
    </div>
</template>

<script>
import { getManageMsgPage } from '@/api/msp/taskwork'

export default {
  data () {
    return ({
      msgQuery: {
        actionDate: '',
        pageNumber: 1,
        pageSize: 10
      },
      totalRow: 0,
      msgList: []
    })
  },
  computed: {
    actionDate () {
      return this.$store.state.dispatch.actionDate
    }
  },
  methods: {
    initPageData () {
      getManageMsgPage(this.msgQuery).then(res => {
        this.totalRow = res.totalRow
        this.msgList = res.list

        if (this.msgQuery.pageNumber === 1 && this.totalRow > 0 && this.msgList[0].type === 2) { // 告警
          this.$store.commit('set_dispatch_show_warning', true)
        }
      })
    },
    handlePageChange (page) {
      this.msgQuery.pageNumber = page
      this.initPageData()
    },
    handleClickMessage (val) {
      this.$store.commit('set_dispatch_stationSvgBean', val)
      this.$store.commit('set_dispatch_show_stationSvg', true)
    }
  },
  watch: {
    actionDate (val) {
      this.msgQuery.actionDate = this.actionDate
      this.initPageData()
    }
  }

}
</script>
