import store from '@/store'
const flickerMarker = require('@/assets/images/flicker_white.gif')
const bluemarker = require('@/assets/images/metro_bluemarker.png')
const redmarker = require('@/assets/images/metro_redmarker.png')
const greenmarker = require('@/assets/images/metro_greenmarker.png')
const orangemarker = require('@/assets/images/metro_orangemarker.png')
const publisherId = store.getters.token.userInfo.publisherId

export function generateResourceImageMarker (base, stationId, name, markerColor) {
  // 气泡图标
  const image = document.createElementNS('http://www.w3.org/2000/svg', 'image')
  image.setAttribute('x', checkImgPoint(name)[0])
  image.setAttribute('y', checkImgPoint(name)[1])
  image.setAttribute('width', '40')
  image.setAttribute('height', '80')
  // image.setAttribute('name', 'metro_marker')
  image.setAttribute('class', 'metro_marker')
  if (markerColor === 'green') {
    image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', greenmarker)
  } else if (markerColor === 'orange') {
    image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', orangemarker)
  } else if (markerColor === 'red') {
    image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', redmarker)
  } else {
    image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', bluemarker)
  }
  image.setAttribute('cursor', 'pointer')
  image.addEventListener('click', () => {
    base.handleClickStation(stationId)
  })

  return image
}

export function generateResourceTextMarker (base, quantity, stationId, name) {
  // 显示可用媒体数量
  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text')
  if (quantity > 99) {
    text.setAttribute('x', checkTextPoint(name)[0] - 8)
  } else if (quantity > 9) {
    text.setAttribute('x', checkTextPoint(name)[0])
  } else { text.setAttribute('x', checkTextPoint(name)[0] + 5) }

  text.setAttribute('y', checkTextPoint(name)[1])
  text.setAttribute('font-size', '20')
  // text.setAttribute('name', 'metro_text')
  text.setAttribute('class', 'metro_text')
  text.setAttribute('fill', '#ffffff')
  text.textContent = quantity
  text.setAttribute('cursor', 'pointer')
  text.addEventListener('click', () => {
    base.handleClickStation(stationId)
  })

  return text
}

export function generateFlickerImageMarker (base, stationId, name) {
  // 气泡图标
  const image = document.createElementNS('http://www.w3.org/2000/svg', 'image')
  image.setAttribute('x', checkStationPoint(name)[0])
  image.setAttribute('y', checkStationPoint(name)[1])
  image.setAttribute('width', '97')
  image.setAttribute('height', '97')
  image.setAttribute('class', 'metro_marker')
  image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', flickerMarker)

  if (base) {
    image.setAttribute('cursor', 'pointer')
    image.addEventListener('click', () => {
      base.handleClickStation(stationId)
    })
  }
  return image
}

export function clearMapMarker () {
  removeElement('metro_marker')
  removeElement('metro_text')
}

export function newGuid () {
  var guid = 'svg_'
  for (var i = 1; i <= 32; i++) {
    var n = Math.floor(Math.random() * 16.0).toString(16)
    guid += n
  }
  return guid
}

export function checkStationidInList (list, id) {
  const Ids = list.split(',')
  if (Ids.length > 1) {
    return Ids.includes(id)
  } else {
    return Ids[0] === id
  }
}

export function checkStationIdByStringInList (list, stationId) {
  const Ids = stationId.split(',')
  let result = false
  for (let index = 0; index < Ids.length; index++) {
    if (list.includes(parseInt(Ids[0]))) {
      result = true
      break
    }
  }

  return result
}

export function checkTextPoint (name) {
  if (publisherId === 13) { // 青岛地铁的地图样式和厦门不一样
    if (name === 'p_r_station') {
      return [8, -10]
    } else {
      return [-5, -18]
    }
  } else {
    if (name === 'p_r_station') {
      return [0, -12]
    } else {
      return [-7, -18]
    }
  }
}

function removeElement (elementName) {
  // let imgMarkers = document.getElementsByName(elementName)   火狐居然不支持这个，我日
  const imgMarkers = document.getElementsByClassName(elementName)
  if (imgMarkers.length > 0) {
    imgMarkers[0].parentNode.removeChild(imgMarkers[0])
    return removeElement(elementName)
  } else {
    return false
  }
}
function checkImgPoint (name) {
  if (publisherId === 13) { // 青岛地铁的地图样式和厦门不一样
    if (name === 'p_r_station') {
      return [0, -50]
    } else {
      return [-13, -60]
    }
  } else {
    if (name === 'p_r_station') {
      return [-10, -55]
    } else {
      return [-15, -60]
    }
  }
}
function checkStationPoint (stationName) {
  const flag = stationName === 'p_r_station'

  if (publisherId === 13) {
    if (flag) {
      return ['-31', '-31']
    } else {
      return ['-39', '-39']
    }
  } else {
    if (flag) {
      return ['-30', '-30']
    } else {
      return ['-39', '-39']
    }
  }
}
