<style scoped>
@import "../../assets/css/dispatch.css";
</style>

<template>
    <div>
        <Left/>
        <Map/>
        <Bottom/>
        <Right/>

        <div v-if="showWarning" class="dispatch-Warning-left"/>
        <div v-if="showWarning" class="dispatch-Warning-right"/>

        <SvgStationDispatch/>
        <SvgLineDispatch/>
        <StationProcess/>
    </div>
</template>

<script>
import Map from './components/Map'
import Left from './components/Left'
import Bottom from './components/Bottom'
import Right from './components/Right'
import SvgStationDispatch from '@/components/svg/SvgStationDispatch'
import SvgLineDispatch from '@/components/svg/SvgLineDispatch'
import StationProcess from './bottom/StationProcess'

// 引入store模块
import dispatchStoreModule from '@/store/modules/dispatch'
import { ParseDate } from '@/utils/dateFormat'

export default {
  components: { Map, Left, Bottom, Right, SvgStationDispatch, SvgLineDispatch, StationProcess },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('dispatch')) {
      this.$store.registerModule('dispatch', dispatchStoreModule)
    }
  },
  mounted () {
    this.$store.commit('set_dispatch_actionDate', ParseDate(new Date()))
    // this.$store.commit('set_dispatch_actionDate', '2020-12-17')
  },
  computed: {
    showWarning () {
      return this.$store.state.dispatch.showWarning
    }
  },
  destroyed () {
    // 卸载动态store模块
    this.$store.unregisterModule('dispatch')
  }
}
</script>
