<template>
    <div class="dispatch-bottom">

            <!-- 按钮集合 -->
            <Row v-if="!showPopup" class="dispatch-bottom-content">
                <i-col span="4" offset="2">
                    <Icon class="dispatch-bottom-button" :color="pageType===1?'#5cadff':''" @click="handleChangePage(1)" type="ios-git-branch"/>
                </i-col>
                <i-col span="4">
                    <Icon class="dispatch-bottom-button" :color="pageType===2?'#5cadff':''" @click="handleChangePage(2)" type="ios-people-outline"/>
                </i-col>
                <i-col span="4">
                    <Icon class="dispatch-bottom-button" @click="handleChangePage(3)" type="ios-paper-outline"/>
                </i-col>
                <i-col span="4">
                    <Icon class="dispatch-bottom-button" @click="handleChangePage(4)" type="ios-stats-outline"/>
                </i-col>
                <i-col span="4">
                    <Icon class="dispatch-bottom-button" @click="handleChangePage(5)" type="ios-stopwatch-outline"/>
                </i-col>
            </Row>

            <!-- 弹出层 -->
            <div v-else>
                <div class="dispatch-bottom-data">
                    <Row>
                      <i-col span="12" class="dispatch-bottom-data-title">
                        <h2>资源进度</h2>
                      </i-col>
                      <i-col span="12">
                        <Icon @click="showPopup=false" class="dispatch-bottom-close" type="md-close"/>
                      </i-col>
                    </Row>

                    <div style="width:100%;height:240px;" id="dispatchReport1"></div>
                </div>
            </div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import { getResourceTypeProgress } from '@/api/msp/taskwork'

export default {
  data () {
    return ({
      pageType: 0,
      showPopup: false
    })
  },
  computed: {
    actionDate () {
      return this.$store.state.dispatch.actionDate
    },
    mapTagType () {
      return this.$store.state.dispatch.mapTagType
    }
  },
  methods: {
    handleChangePage (type) {
      if (this.pageType === type) {
        this.pageType = 0
        this.$store.commit('set_dispatch_map_reload', new Date())
      } else {
        this.pageType = type
        if (type === 1 || type === 2) {
          const mapTagType = type === 1 ? 'line' : 'workgroup'

          this.$store.commit('set_dispatch_map_tagType', mapTagType)
          this.$store.commit('set_dispatch_map_update', new Date())
        } else if (type === 3) {
          this.$store.commit('set_dispatch_show_lineDemandHistory', true)
        } else if (type === 4) {
          this.showPopup = true
          this.loadResourceProgress()
        } else {
          this.$store.commit('set_dispatch_show_stationProcess', true)
        }
      }
    },
    loadResourceProgress () {
      const postData = { actionDate: this.actionDate }

      getResourceTypeProgress(postData).then(res => {
        const reportData = [
          ['product', '作业数', '已完成']
        ]

        res.forEach(element => {
          reportData.push([element.resourcetypeName, element.executingQuantity + element.finishedQuantity, element.finishedQuantity])
        })

        this.initReport(reportData)
      })
    },
    initReport (reportData) {
      this.$nextTick(() => {
        var dataSourcePie = echarts.init(document.getElementById('dispatchReport1'))

        const option = {
          color: ['#2b85e4', '#19be6b'],
          textStyle: {
            color: '#dcdee2'
          },
          legend: {
            data: ['作业数', '已完成'],
            textStyle: {
              color: '#E5E5E5'
            },
            right: 20
          },
          grid: {
            top: '20%',
            left: '5%',
            right: '5%',
            bottom: '10%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999'
              }
            }
          },
          dataset: {
            source: reportData
          },
          xAxis: { type: 'category' },
          yAxis: {},
          series: [
            { type: 'bar' },
            { type: 'bar' }
          ]
        }

        dataSourcePie.setOption(option, true)
        window.addEventListener('resize', function () {
          dataSourcePie.resize()
        })
      })
    }
  },
  watch: {
    actionDate () {
      this.pageType = 0
      this.showPopup = false
    }
  }
}
</script>
