<template>
  <div>
    <Modal v-model="isShow" width="1200" :footer-hide="true" :fullscreen="isFullscreen">
      <Row style="height:600px;"  :gutter="16">
          <div class="fullscreen-content" @click="isFullscreen = !isFullscreen">
            <Icon v-if="isFullscreen" type="ios-contract" size="16" />
            <Icon v-else type="ios-expand" size="16" />
          </div>

          <i-col span="14">
            <template>
              <Tabs v-if="svgList.length>0" v-show="defalutShowSvg" v-model="defaultTab">
                  <TabPane v-for="(item, index) in svgList" :key="index" :label="stationLabel(index)" :name="'tab'+index">
                    <ul style="list-style-type:none;">
                        <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#19be6b"></a>非作业</li>
                        <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#2db7f5"></a>已质检({{qualityTestAmount}})</li>
                        <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#ff9900"></a>未质检({{finishedAmount}})</li>
                        <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#F54236"></a>未作业({{executingAmount}})</li>
                    </ul>

                    <div style="clear:both;"></div>
                    <div v-html="item.svg" name="svgStationHtml" :style="{ height: isFullscreen? '760px':'525px'  }" class="svg-station-content" :id="svgId + '_' + index"></div>
                  </TabPane>
                  <Button size="small" type="primary" ghost slot="extra" @click="handleShowSvgOrList">列表显示</Button>
              </Tabs>
              <div v-else class="default-content">
                <p style="font-size: xx-large;">抱歉，暂无SVG站点地图</p>
              </div>
            </template>
          </i-col>
          <i-col span="13" v-show="!defalutShowSvg">
            <p class="text-right">
              <Button size="small" type="primary" ghost @click="defalutShowSvg=true">返回SVG</Button>
            </p>
            <Divider dashed style="margin: 5px 0;"/>
            <Table stripe :data="tableList" :columns="tableColumns"></Table>
            <div class="paging_style">
              <Page size="small"
                :total="totalRow"
                :page-size="planDetailQuery.pageSize"
                show-total
                :current="planDetailQuery.pageNumber"
                @on-change="handlePageNumberChange"
              ></Page>
            </div>

          </i-col>
          <i-col span="10">
            <Row class="p-b-10">
                <h2 class="p-b-5">当前所属线路:</h2>
                <i-col span="24">
                    <div v-for="(line,index) in lines"  :key="index" @click="changeCheckLines(line,index)" class="m-b-5"
                      :class="line.checked?'workplatform-radio-button-active':'workplatform-radio-button'">{{line.label}}</div>
                </i-col>
            </Row>
            <Row class="p-b-10">
                <h2 class="p-b-5">作业类型:</h2>
                <i-col span="24">
                    <div v-for="(item, index) in domainProgressList" :key="'d'+index" @click="changeTaskitemType(item.taskitemType)" class="m-b-5"
                      :class="item.taskitemType===chooseTaskitemType?'workplatform-radio-button-active':'workplatform-radio-button'">{{item.taskitemTypeName}}</div>
                </i-col>
            </Row>

            <h4 class="workplatform-title">站内作业概览</h4>
            <Divider dashed style="margin: 0 0 5px 0;"/>
            <Row>
              <i-col span="4">作业类型</i-col>
              <i-col span="3">进度</i-col>
              <i-col span="3">完成比</i-col>
              <i-col span="3" class="text-center">人数</i-col>
              <i-col span="4">作业时长</i-col>
              <i-col span="4">剩余时长</i-col>
              <i-col span="3" class="text-center">质检</i-col>
            </Row>
            <Row v-for="(item, index) in domainProgressList" :key="'d'+index">
              <i-col span="4">{{item.taskitemTypeName}}</i-col>
              <i-col span="3" class="text-green">{{item.finishedQuantity}}/{{item.finishedQuantity+item.executingQuantity}}</i-col>
              <i-col span="3" class="text-green">{{computedRate(item)}}</i-col>
              <i-col span="3" class="text-center text-green">{{item.workerQuantity}}</i-col>
              <i-col span="4">{{item.startTime}}</i-col>
              <i-col span="4">{{item.restTime}}</i-col>
              <i-col span="3" class="text-center text-green">{{item.qualityTestQuantity}}</i-col>
            </Row>

            <h4 class="workplatform-title m-t-20">作业小组</h4>
            <Divider dashed style="margin: 0 0 5px 0;"/>
            <Row>
              <i-col span="4">组名</i-col>
              <i-col span="3">进度</i-col>
              <i-col span="3">完成比</i-col>
              <i-col span="3" class="text-center">人数</i-col>
              <i-col span="4">作业时长</i-col>
              <i-col span="4">剩余时长</i-col>
              <i-col span="3" class="text-center">质检</i-col>
            </Row>
            <Row v-for="(item, index) in workgroupProgressList" :key="'w'+index">
              <i-col span="4">{{item.workgroupName}}</i-col>
              <i-col span="3" class="text-green">{{item.finishedQuantity}}/{{item.finishedQuantity+item.executingQuantity}}</i-col>
              <i-col span="3" class="text-green">{{computedRate(item)}}</i-col>
              <i-col span="3" class="text-center text-green">{{item.workerQuantity}}</i-col>
              <i-col span="4">{{item.startTime}}</i-col>
              <i-col span="4">{{item.restTime}}</i-col>
              <i-col span="3" class="text-center text-green">{{item.qualityTestQuantity}}</i-col>
            </Row>

            <h4 class="workplatform-title m-t-20">作业资源分布</h4>
            <Divider dashed style="margin: 0 0 5px 0;"/>
            <Row>
              <i-col span="6">资源类型</i-col>
              <i-col span="6">作业类型</i-col>
              <i-col span="3">进度</i-col>
              <i-col span="3">完成比</i-col>
              <i-col span="3" class="text-center">人数</i-col>
              <i-col span="3" class="text-center">质检</i-col>
            </Row>
            <Row v-for="(item, index) in resourceTypeProgressList" :key="'r'+index">
              <i-col span="6">{{item.resourcetypeName}}</i-col>
              <i-col span="6">{{item.taskitemTypeName}}</i-col>
              <i-col span="3" class="text-green">{{item.finishedQuantity}}/{{item.finishedQuantity+item.executingQuantity}}</i-col>
              <i-col span="3" class="text-green">{{computedRate(item)}}</i-col>
              <i-col span="3" class="text-center">{{item.workerQuantity}}</i-col>
              <i-col span="3" class="text-center text-green">{{item.qualityTestQuantity}}</i-col>
            </Row>
          </i-col>
      </Row>

      <Spin size="large" fix v-if="spinShow">
          <Icon type="ios-loading" size=18 class="-spin-icon-load"></Icon>
          <div>地图加载中</div>
      </Spin>
    </Modal>

    <!-- 弹窗显示上刊照片反馈 -->
      <planAttachInfo ref="modalPlanAttachInfo" />
  </div>
</template>

<script>
import svgPanZoom from 'svg-pan-zoom'

import { newGuid } from '@/utils/mapCommon'
import { getRimStationSvgByStationId } from '@/api/rim/stationgraph'
import { getDomainProgress, getWorkGroupProgress, getResourceTypeProgress, getResourceIdList } from '@/api/msp/taskwork'
import { getTaskItemList } from '@/api/msp/taskitem'
import { getPlanDetailPage } from '@/api/msp/plan'
import { formartMspPlanStatus } from '@/utils/tagStatus'

import planAttachInfo from '@/components/msp/planAttachInfo'

export default {
  components: {
    planAttachInfo
  },
  data () {
    return ({
      isFullscreen: false,
      isShow: false,
      spinShow: false,
      currentStationSvgID: 0, // 当前选中的站点ID
      lines: [], // 包含的线路

      svgList: [],
      defaultTab: 'tab0',
      svgId: '',
      stationTabLables: [], // 用于标注点位数量
      pathNodeList: [],

      chooseTaskitemType: null, // 选中的作业类型
      domainProgressList: [], // 站内作业概览
      resourceTypeProgressList: [], // 作业资源分布
      workgroupProgressList: [], // 作业小组执行情况

      executingAmount: 0, // 执行中的资源数量
      finishedAmount: 0, // 待质检数量
      qualityTestAmount: 0, // 已质检的资源数量

      modalTaskDemandInfo: false, // 弹窗显示任务上刊详情
      chooseTaskItem: {}, // 默认选中的任务项
      taskDemandList: [], // 任务上刊详情
      choosePhoneType: 1, // 选择的照片类型
      demandImgIndex: 0,

      defalutShowSvg: true,
      planDetailQuery: {
        actionDate: '',
        domainId: 0,
        taskitemType: 0,
        pageNumber: 1,
        pageSize: 10
      },
      tableList: [],
      totalRow: 0,
      tableColumns: [
        {
          title: '资源编号',
          render: (h, data) => {
            return h('span', { }, data.row.resourceCode || '无')
          }
        },
        {
          title: '位置',
          render: (h, data) => {
            return h('span', { }, data.row.positionName || data.row.stationName)
          }
        },
        { title: '任务类型', key: 'typeName' },
        { title: '更新时间', key: 'updateTime' },
        {
          title: '状态',
          width: 100,
          align: 'center',
          render: (h, data) => {
            // return h('tag', { props: { color: formatMspPlanStatusColor(data.row.status) } }, data.row.statusName)
            return formartMspPlanStatus(h, data.row.status, data.row.statusName)
          }
        },
        {
          title: '质检',
          width: 80,
          align: 'center',
          render: (h, data) => {
            if (data.row.qualityTesting) {
              return h('Icon', {
                props: { type: 'md-checkbox-outline', size: 16, color: '#19be6b' }
              })
            } else {
              return h('Icon', {
                props: { type: 'md-close', size: 16, color: '#ed4014' }
              })
            }
          }
        },
        {
          title: '详情',
          width: 80,
          align: 'center',
          render: (h, data) => {
            return h('a', {
              on: {
                click: () => {
                  this.handleClickResource(data.row.resourceId)
                }
              }
            }, '详情')
          }
        }
      ]
    })
  },
  methods: {
    loadAssetInfo () {
      this.lines = []
      if (this.stationSvgBean.assetName.split(',').length > 1) { // 存在多条线路和站点信息
        const assetNames = this.stationSvgBean.assetName.split(',')
        const assetIds = this.stationSvgBean.assetId.toString().split(',')
        const stationIds = this.stationSvgBean.domainId.toString().split(',')

        for (let index = 0; index < assetNames.length; index++) {
          this.lines.push({
            id: assetIds[index],
            label: assetNames[index],
            domainId: parseInt(stationIds[index]),
            checked: index === 0
          })
        }
        this.currentStationSvgID = parseInt(stationIds[0])
      } else {
        this.currentStationSvgID = this.stationSvgBean.domainId
        this.lines = [{
          id: this.stationSvgBean.assetId,
          label: this.stationSvgBean.assetName,
          domainId: this.stationSvgBean.domainId,
          checked: true
        }]
      }
    },
    initPageData () {
      this.spinShow = true
      this.loadPageTableData()
      // 获取站点svg条件字段封装
      const postData = {
        stationIds: this.currentStationSvgID
      }

      getRimStationSvgByStationId(postData).then(res => {
        this.svgList = res
        this.stationTabLables = []
        let label
        for (let index = 0; index < res.length; index++) {
          label = (res[index].floor > 0) ? '地上' : '地下'
          label += res[index].floor + '层' + res[index].positionName
          this.stationTabLables.push({
            label: label,
            quantity: 0
          })
        }

        if (this.svgList.length > 0) {
          this.defaultTab = 'tab0'

          this.$nextTick(() => {
            this.svgList.forEach((element, index) => {
              const cuurentSvgElement = document.getElementById(this.svgId + '_' + index).firstElementChild
              // 设置svg呈现在画板上的尺寸
              cuurentSvgElement.setAttribute('width', '100%')
              cuurentSvgElement.setAttribute('height', '100%')

              svgPanZoom(cuurentSvgElement, {
                panEnabled: true,
                controlIconsEnabled: false,
                zoomEnabled: true,
                dblClickZoomEnabled: true,
                center: true
              }).zoom(1.3)
            })
            this.pathNodeList = document.getElementsByTagName('path')
            this.loadSvgResourceList()
          })
        } else {
          this.spinShow = false
        }
      })
    },
    loadPageTableData () {
      const postData = {
        actionDate: this.actionDate,
        domainId: this.currentStationSvgID,
        showtaskitemtype: true
      }

      getDomainProgress(postData).then(res => {
        this.domainProgressList = res
        if (this.stationSvgBean.taskitemType) {
          this.chooseTaskitemType = this.stationSvgBean.taskitemType
        } else {
          this.chooseTaskitemType = res[0].taskitemType
        }
      })

      getResourceTypeProgress(postData).then(res => {
        this.resourceTypeProgressList = res
      })

      getWorkGroupProgress(postData).then(res => {
        this.workgroupProgressList = res
      })
    },
    changeCheckLines (line, i) {
      if (this.currentStationSvgID !== this.lines[i].domainId) {
        this.currentStationSvgID = line.domainId
        this.lines.forEach(element => {
          element.checked = element.domainId === this.currentStationSvgID
        })

        this.defaultTab = 'tab0'
        this.svgList = []
        this.svgId = newGuid()
        this.initPageData()
      }
    },
    changeTaskitemType (type) {
      this.chooseTaskitemType = type
      this.loadSvgResourceList()
      this.planDetailQuery.pageNumber = 1
      this.loadPlanDetailList()
    },
    tagResource (resourceIds, color) {
      if (this.pathNodeList.length > 0 && resourceIds.length > 0) {
        for (let i = 0; i < this.pathNodeList.length; i++) {
          const el = this.pathNodeList[i]

          if (el.hasAttribute('data-id')) {
            // 判断灯箱点位是否包含在清单中
            const resourceId = parseInt(el.getAttribute('data-id'))
            if (resourceIds.indexOf(resourceId) > -1) {
              el.setAttribute('fill', color) // 变更资源颜色
              el.setAttribute('cursor', 'pointer')
              // 注册点击事件，并调用父组件方法
              el.addEventListener('click', (e) => {
                if (el.getAttribute('fill') !== '#008100') {
                  this.handleClickResource(resourceId)
                }
              })
            }
          }
        }
      }
    },
    restoreResouceColor () { // 还原所有标记的颜色
      if (this.pathNodeList.length > 0) {
        for (let i = 0; i < this.pathNodeList.length; i++) {
          const el = this.pathNodeList[i]

          if (el.hasAttribute('data-id')) {
            el.setAttribute('fill', '#008100') // 变更资源颜色
            el.setAttribute('cursor', 'auto')
          }
        }
      }
    },
    loadSvgResourceList () { // 加载上刊数量
      this.spinShow = false
      this.restoreResouceColor()

      const postData = {
        actionDate: this.actionDate,
        domainId: this.currentStationSvgID,
        taskitemType: this.chooseTaskitemType
      }

      getResourceIdList(postData).then(res => {
        this.formatSvgTaglable(res.floorQuantityList)

        if (res.executingResourceIdList.length > 0) { // 执行中的资源主键集合
          this.tagResource(res.executingResourceIdList, '#F54236')
          this.executingAmount = res.executingResourceIdList.length
        } else {
          this.executingAmount = 0
        }

        const qualityTestResourceIdList = res.qualityTestResourceIdList
        if (res.finishedResourceIdList.length > 0) { // 已完成（待质检）的资源主键集合
          this.tagResource(res.finishedResourceIdList, '#ff9900')
          // 计算已完成，并且待质检的资源数量
          this.finishedAmount = 0
          res.finishedResourceIdList.forEach(element => {
            if (!qualityTestResourceIdList.includes(element)) {
              this.finishedAmount++
            }
          })
        } else {
          this.finishedAmount = 0
        }

        if (qualityTestResourceIdList.length > 0) { // 已质检的资源主键集合
          this.tagResource(qualityTestResourceIdList, '#2db7f5')
          this.qualityTestAmount = qualityTestResourceIdList.length
        } else {
          this.qualityTestAmount = 0
        }
      })
    },
    handleClickResource (resourceId) {
      const postData = {
        actionDate: this.actionDate,
        resourceId: resourceId,
        taskitemType: this.chooseTaskitemType
      }

      getTaskItemList(postData).then(res => {
        this.$refs.modalPlanAttachInfo.loadPlanInfo(res, true)
      })
    },

    formatSvgTaglable (floorQuantityList) {
      // 开始重新格式化Tab标签
      let index = 0
      let floorStatisticBySvgHtml
      this.svgList.forEach(svgHtml => {
        floorStatisticBySvgHtml = floorQuantityList.find(x => x.floor === svgHtml.floor)

        this.stationTabLables[index].quantity = floorStatisticBySvgHtml ? floorStatisticBySvgHtml.quantity : 0
        index++
      })

      this.stationTabLables = Object.assign({}, this.stationTabLables)
    },
    stationLabel (index) {
      return (h) => {
        return h('div', [
          h('span', this.stationTabLables[index].label),
          h('Badge', {
            style: {
              left: '5px',
              top: '-10px'
            },
            props: {
              count: this.stationTabLables[index].quantity
            }
          })
        ])
      }
    },
    computedRate (val) {
      if (val.executingQuantity + val.finishedQuantity > 0) {
        return (val.finishedQuantity / (val.executingQuantity + val.finishedQuantity) * 100).toFixed(2) + '%'
      } else {
        return '0.00%'
      }
    },
    handleShowSvgOrList () {
      this.defalutShowSvg = false
      this.planDetailQuery.pageNumber = 1
      this.loadPlanDetailList()
    },
    loadPlanDetailList () {
      this.planDetailQuery.actionDate = this.actionDate
      this.planDetailQuery.domainId = this.currentStationSvgID
      this.planDetailQuery.taskitemType = this.chooseTaskitemType

      getPlanDetailPage(this.planDetailQuery).then(res => {
        this.tableList = res.list
        this.totalRow = res.totalRow
      })
    },
    handlePageNumberChange (page) {
      this.planDetailQuery.pageNumber = page
      this.loadPlanDetailList()
    }
  },
  computed: {
    actionDate () {
      return this.$store.state.dispatch.actionDate
    },
    showStationSvg () {
      return this.$store.state.dispatch.showStationSvg
    },
    stationSvgBean () {
      return this.$store.state.dispatch.stationSvgBean
    }
  },
  watch: {
    showStationSvg (val) {
      if (val) {
        this.isShow = true
        this.defalutShowSvg = true
        this.defaultTab = 'tab0'
        this.svgList = []
        this.svgId = newGuid()
        this.loadAssetInfo()
        this.initPageData()
      }
    },
    isShow (val) {
      if (!val) {
        this.$store.commit('set_dispatch_show_stationSvg', false)
      }
    }
  }
}
</script>
