<template>
    <div class="dispatch-left">
        <Row>
          <i-col span="12" class="p-l-10 p-b-5">
            <label class="text-24">调度台</label><br/>
            <label>调度人员：{{$store.state.user.token.userInfo.name}}</label>
          </i-col>
          <i-col span="12" class="text-right p-r-5">
            <DatePicker v-model="datePickerAction" :options="optionsActionDate"  @on-change="handleChaneActionDate" type="date" transfer :clearable="false" style="width: 120px;" class="m-t-20"></DatePicker>
          </i-col>
        </Row>

        <Divider dashed style="margin: 0 0 2px 0;"/>

        <div class="dispatch-left-title">作业总览</div>
        <Row class="text-center">
            <i-col span="6">进度</i-col>
            <i-col span="6">质检</i-col>
            <i-col span="6">完成比</i-col>
            <i-col span="6">作业时长</i-col>
        </Row>
        <Row class="text-center text-14 text-bold m-b-10">
            <i-col span="6"><span class="text-green">{{overAllBean.finishedQuantity}}</span> / {{overAllBean.executingQuantity + overAllBean.finishedQuantity}}</i-col>
            <i-col span="6" class="text-orange">{{overAllBean.qualityTestQuantity}}</i-col>
            <i-col span="6">{{computedRate(overAllBean)}}</i-col>
            <i-col span="6">{{overAllBean.startTime}}</i-col>
        </Row>

        <div class="dispatch-left-title">站点作业进度</div>
        <Tabs :value="chooseStaionProcessTabName" @on-click="handleChangeStationProcessType">
            <TabPane v-for="tabItem in stationProcessTab" :key="tabItem.taskitemType"
              :label="tabItem.taskitemTypeName + ' (' + tabItem.finishedQuantity + '/' + (tabItem.executingQuantity + tabItem.finishedQuantity)  + ')'"
              :name="tabItem.taskitemTypeName"></TabPane>
        </Tabs>
        <p v-if="stationProcessByGroupList.length===0" class="text-center p-t-20 text-16 text-gray" >暂无数据</p>
        <Row v-else class="m-b-5">
            <i-col span="6">站点</i-col>
            <i-col span="3">进度</i-col>
            <i-col span="4">完成比</i-col>
            <i-col span="3">人数</i-col>
            <i-col span="4">作业时长</i-col>
            <i-col span="4">剩余时长</i-col>
        </Row>
        <Row v-for="(item,index) in stationProcessByGroupList" :key="'station' + index"
          class="dispatch-left-row" :class="chooseStationId===item.domainId?'dispatch-left-row-active':''">
          <i-col span="24">
            <div @click="handleClickStation(item)">
              <Row>
                <i-col span="6">{{item.domainName}}</i-col>
                <i-col span="3"><span class="text-green">{{item.finishedQuantity}}</span> / {{item.executingQuantity + item.finishedQuantity}}</i-col>
                <i-col span="4">{{computedRate(item)}}</i-col>
                <i-col span="3">{{item.workerQuantity}}</i-col>
                <i-col span="4">{{item.startTime}}</i-col>
                <i-col span="4">{{item.restTime}}</i-col>
              </Row>
            </div>
          </i-col>
        </Row>

        <br/>
        <div class="dispatch-left-title">供应商作业进度</div>
        <p v-if="workgroup.length===0" class="text-center p-t-20 text-16 text-gray" >暂无数据</p>
        <div v-for="item in workgroup" :key="item.companyId">
          <p class="text-14 p-t-10 p-l-5 p-r-5">{{item.companyName}} ({{item.finishedQuantity}}/{{item.finishedQuantity + item.executingQuantity}})</p>
          <Divider dashed style="margin: 2px;"/>
          <Row class="m-b-5">
            <i-col span="5">组名</i-col>
            <i-col span="4">进度</i-col>
            <i-col span="4">完成比</i-col>
            <i-col span="3">人数</i-col>
            <i-col span="4">作业时长</i-col>
            <i-col span="4">剩余时长</i-col>
          </Row>
          <Row v-for="progress in workgroupProgressList.filter(x=>x.companyId === item.companyId)" :key="'workgroup' + progress.workgroupId"
            class="dispatch-left-row" :class="chooseWorkgroupId===progress.workgroupId?'dispatch-left-row-active':''">
            <i-col span="24">
              <div @click="handleClickWorkgroup(progress.workgroupId)">
                <Row>
                  <i-col span="5">{{progress.workgroupName}}</i-col>
                  <i-col span="4"><span class="text-green">{{progress.finishedQuantity}}</span> / {{progress.finishedQuantity+progress.executingQuantity}}</i-col>
                  <i-col span="4">{{computedRate(progress)}}</i-col>
                  <i-col span="3">{{progress.workerQuantity}}</i-col>
                  <i-col span="4">{{progress.startTime}}</i-col>
                  <i-col span="4">{{progress.restTime}}</i-col>
                </Row>
              </div>
            </i-col>
          </Row>
        </div>
    </div>
</template>

<script>
import { getOverAll, getDomainProgress, getWorkGroupProgress } from '@/api/msp/taskwork'
import { getactiondatequantity } from '@/api/msp/plan'
import { ParseDate } from '@/utils/dateFormat'

export default {
  data () {
    return ({
      datePickerAction: '',
      optionsActionDate: {},
      overAllBean: {}, // 作业总览

      stationProcessList: [], // 站点进度
      stationProcessTab: [],
      chooseStaionProcessTabName: '', // tab标签的默认值
      stationProcessByGroupList: [],
      chooseStationId: 0, // 选中的站点行

      workgroup: [],
      chooseWorkgroupId: 0,
      workgroupProgressList: []
    })
  },
  computed: {
    actionDate () {
      return this.$store.state.dispatch.actionDate
    },
    mapTagType () {
      return this.$store.state.dispatch.mapTagType
    },
    mapDate () { // 用户页面底部按钮，执行显示工作区域和路劲
      return this.$store.state.dispatch.mapDate
    },
    beginUpdate () {
      return this.$store.state.dispatch.beginUpdate
    }
  },
  mounted () {
    this.initOptionsActionDate()
  },
  methods: {
    initPageData () {
      this.datePickerAction = this.actionDate
      const postData = { actionDate: this.actionDate }

      getOverAll(postData).then(res => {
        this.overAllBean = res
        this.$store.commit('set_dispatch_lastestTime', res.lastestTime)
      })

      this.loadDomainProgressData(postData)
      this.loadWorkGroupProgress(postData)
    },
    handleChaneActionDate (date) {
      this.$store.commit('set_dispatch_actionDate', date)
    },
    loadDomainProgressData (postData) {
      getDomainProgress(postData).then(res => {
        this.stationProcessList = res
        this.$store.commit('set_dispatch_domain_progress', res)
        this.$store.commit('set_dispatch_map_reload', new Date())
        // 按类型分组
        this.stationProcessTab = []
        let tabItem
        res.forEach(element => {
          tabItem = this.stationProcessTab.find(x => x.taskitemType === element.taskitemType)
          if (tabItem) {
            tabItem.finishedQuantity += element.finishedQuantity
            tabItem.executingQuantity += element.executingQuantity
          } else {
            this.stationProcessTab.push({
              taskitemType: element.taskitemType,
              taskitemTypeName: element.taskitemTypeName,
              finishedQuantity: element.finishedQuantity,
              executingQuantity: element.executingQuantity
            })
          }
        })

        // 设置TAB默认选中值
        if (this.stationProcessTab.length > 0) {
          this.chooseStaionProcessTabName = this.stationProcessTab[0].taskitemTypeName
        } else {
          this.chooseStaionProcessTabName = ''
        }

        this.handleChangeStationProcessType(this.chooseStaionProcessTabName)
      })
    },
    handleChangeStationProcessType (name) {
      this.stationProcessByGroupList = this.stationProcessList.filter(x => x.taskitemTypeName === name)
    },
    loadWorkGroupProgress (postData) {
      this.workgroup = []
      let tabItem = null

      getWorkGroupProgress(postData).then(res => {
        this.workgroupProgressList = res

        res.forEach(element => {
          tabItem = this.workgroup.find(x => x.companyId === element.companyId)
          if (tabItem) {
            tabItem.finishedQuantity += element.finishedQuantity
            tabItem.executingQuantity += element.executingQuantity
          } else {
            this.workgroup.push({
              companyId: element.companyId,
              companyName: element.companyName,
              finishedQuantity: element.finishedQuantity,
              executingQuantity: element.executingQuantity
            })
          }
        })
      })
    },
    handleClickStation (stationBean) {
      this.chooseStationId = stationBean.domainId
      this.chooseWorkgroupId = 0

      this.$store.commit('set_dispatch_stationSvgBean', stationBean)
      this.$store.commit('set_dispatch_show_stationSvg', true)
    },
    handleClickWorkgroup (workgroupId) {
      this.chooseStationId = 0
      this.chooseWorkgroupId = workgroupId

      this.$store.commit('set_dispatch_map_tagType', 'tag')
      this.$store.commit('set_dispatch_map_tag_workgroupId', workgroupId)
      this.$store.commit('set_dispatch_map_update', new Date())
    },
    computedRate (val) {
      if (val.executingQuantity + val.finishedQuantity > 0) {
        return (val.finishedQuantity / (val.executingQuantity + val.finishedQuantity) * 100).toFixed(2) + '%'
      } else {
        return '0.00%'
      }
    },
    initOptionsActionDate () {
      const postData2 = {
        statuss: JSON.stringify([2, 3])
      }
      getactiondatequantity(postData2).then(res => {
        const allowDate = res.map(x => x.actionDate)

        this.optionsActionDate = {
          disabledDate: date => {
            return !allowDate.includes(ParseDate(date))
          }
        }
      })
    }
  },
  watch: {
    mapDate (val) {
      if (this.mapTagType !== 'tag') {
        this.chooseWorkgroupId = 0
        this.chooseStationId = 0
      }
    },
    actionDate (val) {
      this.initPageData()
    }
  }
}
</script>
