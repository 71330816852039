const dispatch = {
  state: {
    actionDate: '',
    showWarning: false,
    showStationSvg: false,
    stationSvgBean: null,
    mapReload: new Date(),
    domainProgress: [],
    lastestTime: new Date(),
    mapDate: new Date(),
    mapTagType: '',
    mapTagWorkgroupId: 0,
    beginUpdate: new Date(), // 用于全局更新
    lineDemandHistory: false,
    stationProcess: false
  },
  mutations: {
    set_dispatch_actionDate (state, data) {
      state.actionDate = data
    },

    set_dispatch_map_reload (state, data) {
      state.mapReload = data
    },
    set_dispatch_domain_progress (state, data) {
      state.domainProgress = data
    },
    set_dispatch_lastestTime (state, data) { // 最后截止时间
      state.lastestTime = data
    },

    set_dispatch_update (state, data) { // 刷新全局数据
      state.beginUpdate = data
    },
    set_dispatch_map_update (state, data) {
      state.mapDate = data
    },
    set_dispatch_map_tagType (state, data) {
      state.mapTagType = data
    },
    set_dispatch_map_tag_workgroupId (state, data) {
      state.mapTagWorkgroupId = data
    },

    set_dispatch_stationSvgBean (state, data) {
      state.stationSvgBean = data
    },
    set_dispatch_show_stationSvg (state, data) {
      state.showStationSvg = data
    },
    set_dispatch_show_warning (state, data) {
      state.showWarning = data
    },
    set_dispatch_show_lineDemandHistory (state, data) { // 弹窗显示上刊轨迹
      state.lineDemandHistory = data
    },
    set_dispatch_show_stationProcess (state, data) { // 弹窗显示站点作业进程图
      state.stationProcess = data
    }

  },
  getters: {

  },
  actions: {

  }
}

export default dispatch
