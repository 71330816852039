<template>
  <Modal
    v-model="modalDemandInfo"
    width="800"
    :footer-hide="true"
  >
    <div slot="header">
      <Row class="p-b-10">
        <label class="title m-r-5">任务项</label>
        <div
          v-for="(item, index) in taskDemandList"
          :key="index"
          @click="changeTaskItem(item)"
          class="m-b-5"
          :class="
            item.id === chooseTaskItem.id
              ? 'workplatform-radio-button-active'
              : 'workplatform-radio-button'
          "
        >
          {{ item.typeName }}
        </div>
      </Row>
      <Row
        class="p-b-10"
        v-if="[1,2,7].includes(chooseTaskItem.type)"
      >
        <i-col span="8"><label class="title m-r-5">客户名称</label> {{ chooseTaskItem.taskOrder.advertiserName }} </i-col>
        <i-col span="16"><label class="title m-r-5">品牌名称</label> {{ chooseTaskItem.taskOrder.brandName }} </i-col>
      </Row>
      <Row class="p-b-10">
        <i-col span="8"><label class="title m-r-5">资源编号</label> {{ chooseTaskItem.resourceCode }} </i-col>
        <i-col span="6"><label class="title m-r-5">站点</label> {{ chooseTaskItem.domainName }} </i-col>
        <i-col span="6"><label class="title m-r-5">当前状态</label>{{ chooseTaskItem.statusName }} </i-col>
      </Row>
      <Row
        class="m-b-10"
        v-if="chooseTaskItem.taskitemLabor"
      >
        <i-col span="8">
          <label class="title m-r-5">工作单位</label> {{ chooseTaskItem.taskitemLabor.companyName }}
        </i-col>
        <i-col span="6">
          <label class="title m-r-5">工作小组</label>{{ chooseTaskItem.taskitemLabor.workgroupName }}
        </i-col>
        <i-col span="6">
          <label class="title m-r-5">完成时间</label>{{ chooseTaskItem.finishTime }}
        </i-col>
      </Row>
      <Row class="m-b-10">
        <i-col span="16"><label class="title m-r-5">备注</label>{{ chooseTaskItem.remark+chooseTaskItem.extra }} </i-col>
        <i-col
          span="8"
          class="text-right"
          v-if="!hideFooter"
        >
          <Button
            type="success"
            size="small"
            v-if="isAuth('inspection_index_confrim')&&!chooseTaskItem.qualityTesting"
            @click="handleConfirmTask"
          >确认质检</Button>
          <Button
            class="m-l-5"
            type="success"
            size="small"
            v-if="isAuth('inspection_index_check')&&!chooseTaskItem.qualityTesting"
            @click="handleCheckTask"
          >质检</Button>
          <Button
            class="m-l-5"
            type="success"
            size="small"
            v-if="isAuth('inspection_index_checkamount')&&chooseTaskItem.qualityTesting&&chooseTaskItem.chargeStatus===1"
            @click="handleCheckAmount"
          >确认对账</Button>
          <Button
            class="m-l-5"
            size="small"
            type="warning"
            v-if="isAuth('inspection_index_remake')&&chooseTaskItem.type!==3"
            @click="handleRemakePhotoTask"
          >重拍</Button>
        </i-col>

      </Row>
      <div
        class="m-b-10"
        v-if="chooseTaskItem.taskitemCharges&&chooseTaskItem.taskitemCharges.length"
      >
        <Table
          stripe
          size="small"
          :data="chooseTaskItem.taskitemCharges"
          :columns="costColumns"
        ></Table>
      </div>
    </div>

    <div class="text-center">
      <Row class="p-b-5">
        <i-col span="24">
          <div
            @click="handleChangeImgType(1)"
            :class="
              choosePhoneType === 1
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
            "
            class="p-l-20 p-r-20"
          >
            任务素材
          </div>
          <div
            @click="handleChangeImgType(2)"
            :class="
              choosePhoneType === 2
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
            "
            class="p-l-20 p-r-20"
          >
            反馈照片
          </div>
        </i-col>
      </Row>
      <div
        class="m-t-5 m-b-5 text-right"
        v-if="choosePhoneType===2&&existFeedbackImg"
      >
        <Button
          type="success"
          size="small"
          @click="viewBigImage"
        >查看原图</Button>
      </div>
      <img-light-box
        ref="imgLightBox"
        :imgIndex.sync="demandImgIndex"
      />
    </div>
    <!-- <div slot="footer">
      <Button type="success" @click="handleConfirmTask">确认质检</Button>
      <Button class="m-l-5" type="warning" @click="handleRemakePhotoTask"
        >重拍</Button
      >
    </div> -->
  </Modal>
</template>

<script>
import ImgLightBox from '@/components/common/ImgVideoLightBox'
import { toMoney } from '@/utils/wnumb_own'
import { confirmFinish, remakePhoto, confirmAmount } from '@/api/msp/taskitem'

export default {
  components: {
    ImgLightBox
  },
  data () {
    return {
      hideFooter: true,
      hideFooterdefault: true,
      taskDemandList: [],
      chooseTaskItem: {},

      modalDemandInfo: false,
      choosePhoneType: 1,
      demandImgIndex: 0,
      existFeedbackImg: false,
      costColumns: [
        { title: '计费类型', align: 'center', key: 'costTypeName' },
        {
          title: '计费单价',
          align: 'center',
          key: 'unitCost',
          render: (h, params) => {
            return h('span', toMoney(params.row.confirmChargeUnitCost))
          }
        },
        {
          title: '计费数量',
          align: 'center',
          key: 'confirmChargeQuantity',
          render: (h, params) => {
            return h('span', `${params.row.confirmChargeQuantity}${params.row.confirmChargeTypeName}`)
          }
        },
        {
          title: '计费金额',
          align: 'center',
          key: 'confirmCost',
          render: (h, params) => {
            return h('span', toMoney(params.row.confirmCost))
          }
        },
        { title: '备注', align: 'center', key: 'remark' }
      ],
      remakeRemark: ''
    }
  },
  methods: {
    loadPlanInfo (taskDemandList, hideFooterdefault) {
      this.modalDemandInfo = true
      this.hideFooterdefault = hideFooterdefault
      this.taskDemandList = taskDemandList

      this.changeTaskItem(taskDemandList.length > 0 ? taskDemandList[0] : {})
    },
    changeTaskItem (taskItem) {
      this.chooseTaskItem = taskItem
      if (this.hideFooterdefault) {
        this.hideFooter = this.hideFooterdefault
      } else {
        this.hideFooter = this.chooseTaskItem.status !== 3
      }
      this.handleChangeImgType(1)
    },
    handleChangeImgType (type) {
      this.choosePhoneType = type
      const postData = {
        startIndex: 0,
        imgList: []
      }

      if (type === 1) {
        postData.imgList = this.chooseTaskItem.taskitemFiles
      } else {
        postData.imgList = this.chooseTaskItem.taskitemAttachs
        this.existFeedbackImg = postData.imgList.length
      }
      this.$refs.imgLightBox.init(postData)
    },
    /**
     * 确认质检-质检+对账
     */
    handleConfirmTask () {
      const that = this
      that.$Modal.confirm({
        title: '操作提示',
        content: '您确认要将当前的作业任务设置为已质检，并发起对账吗？',
        onOk: () => {
          const postData = {
            taskitemIds: JSON.stringify([that.chooseTaskItem.id])
          }
          confirmFinish(postData).then((res) => {
            that.$Modal.remove()
            that.handleCheckSuccess(res)
          }).catch(() => {
            that.$Modal.remove()
          })
        }
      })
    },
    /**
     * 确认质检-只质检
     */
    handleCheckTask () {
      this.$emit('on-confirm-check', [this.chooseTaskItem.id])
      // const that = this
      // that.$Modal.confirm({
      //   title: '操作提示',
      //   content: '您确认要将当前的作业任务设置为已质检吗？',
      //   onOk: () => {
      //     const postData = {
      //       taskitemIds: JSON.stringify([that.chooseTaskItem.id])
      //     }
      //     confirmTaskFinish(postData).then(res => {
      //       that.$Modal.remove()
      //       that.handleCheckSuccess(res)
      //     }).catch(() => {
      //       that.$Modal.remove()
      //     })
      //   }
      // })
    },
    /**
    * 确认质检-只发起对账
    */
    handleCheckAmount () {
      const that = this
      that.$Modal.confirm({
        title: '操作提示',
        content: '您确认要对当前的作业任务发起对账？',
        onOk: () => {
          const postData = {
            taskitemIds: JSON.stringify([that.chooseTaskItem.id])
          }
          confirmAmount(postData).then(res => {
            that.$Modal.remove()
            that.handleCheckSuccess(res)
          }).catch(() => {
            that.$Modal.remove()
          })
        }
      })
    },
    handleCheckSuccess (res) {
      if (res && res.errcode === 0) {
        this.modalDemandInfo = false
        this.$emit('on-reloadList')
        // this.$Notice.success({ desc: '确认作业操作成功' })
      }
    },
    handleRemakePhotoTask () {
      const that = this
      that.$Modal.confirm({
        title: '操作提示',
        render: (h) => {
          return h('div', [
            h('p', {
              style: { marginBottom: '10px' }
            }, '确定要发起重拍？'),
            h('Input', {
              props: {
                value: that.remakeRemark,
                autofocus: true,
                size: 'small',
                type: 'textarea',
                rows: 2,
                placeholder: '填写重拍备注（非必填）'
              },
              on: {
                input: (val) => {
                  that.remakeRemark = val
                }
              }
            })
          ])
        },
        onOk: () => {
          const postData = {
            taskitemIds: JSON.stringify([that.chooseTaskItem.id]),
            remark: that.remakeRemark
          }

          remakePhoto(postData).then((res) => {
            that.$Modal.remove()
            that.remakeRemark = ''
            if (res && res.errcode === 0) {
              that.modalDemandInfo = false
              that.$emit('on-reloadList')
              that.$Notice.success({ desc: '已成功发起重拍任务' })
            } else {
              that.$Notice.error({
                title: '重拍任务操作失败',
                desc: res.errmsg
              })
            }
          }).catch(() => {
            that.$Modal.remove()
          })
        }
      })
    },
    /**
     * 查看原图
     */
    viewBigImage () {
      this.$refs.imgLightBox.viewBigImg()
    },
    fomartMoney (number) {
      return toMoney(number)
    },
    onCloseModal () {
      this.modalDemandInfo = false
    }
  }
}
</script>
